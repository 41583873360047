import axios from "axios";
import React, { useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Link, useNavigate, useParams } from "react-router-dom";
import Footer from "../../../Common/Footer";
import Loyaltyheader from "../../../Common/Loyaltyheader";
import Toastmodal from "../../../Common/Toastmodal";
import Config from "../../../Config";

function Loyaltyprogramedit() {
  let navigate = useNavigate();
  const [tab, setTab] = useState("EN");
  const [ratelist, setRatelist] = useState([]);
  const [loyalty, setLoyalty] = useState([]);
  const [language, setlanguage] = useState([]);
  const [offers, setOffers] = useState([]);
  const [extras, setExtras] = useState([]);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [currency, setCurrency] = useState([]);
  const [selectedrate, setSelectedrate] = useState([]);
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [selectedOffers, setSelectedOffers] = useState([]);
  const [selectedLoyalty, setSelectedLoyalty] = useState([]);
  const [usecase, setUsecase] = useState("");
  const [newLang, setNewLang] = useState("");
  const [selectedMulti, setselectedMulti] = useState([]);
  const [isMatchingLanguage, setisMatchingLanguage] = useState("");
  const [list, setList] = useState([]);
  const [formerror, setError] = useState({});

  let params = useParams();
  const [programid, setProgramid] = useState(params.program_id);
  const [hotelid, setHotelid] = useState(params.hotel_id);

  const [selectedratenew, setSelectedratenew] = useState([]);
  const [selectedExtrasnew, setSelectedExtrasnew] = useState([]);
  const [selectedOffersnew, setSelectedOffersnew] = useState([]);
  const [selectedLoyaltynew, setSelectedLoyaltynew] = useState([]);
  const [langall, setLangall] = useState([]);

  const handleConditionChange = (name, value) => {
    const updatedValues = { ...list, [name]: value };
    setList(updatedValues);
  };

  const handleloyaltyToggle = (isChecked, name) => {
    setList({ ...list, [name]: isChecked });
  };
  const handleLanguageChangenew = (selectedOption) => {
    console.log(selectedOption);
    setNewLang(selectedOption);
    const updatedLanguages = [...selectedMulti];
    if (updatedLanguages.length > 0) {
      if (!updatedLanguages.some((item) => item.code === selectedOption)) {
        updatedLanguages.push({ code: selectedOption, name: "" });
      }
    } else {
      updatedLanguages.push({ code: selectedOption, name: "" });
    }
    setselectedMulti(updatedLanguages);
  };

  const handleloyallangChange = (Value) => {
    const updatedList = [...selectedMulti];
    const matchingIndex = updatedList.findIndex(
      (language) => language.code === newLang
    );
    if (matchingIndex !== -1) {
      updatedList[matchingIndex]["name"] = Value;
    } else {
      const newLanguage = { code: newLang };
      newLanguage["name"] = Value;
      updatedList.push(newLanguage);
    }
    setselectedMulti(updatedList);
  };

  const getListall = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/loyalty/programs/all`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: hotelid,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data1 = await res.json();
    const data = data1.data;
    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setCurrency(data[0].currency);
      setRatelist(data[0].rate_lists);
      setOffers(data[0].offers);
      setExtras(data[0].extras);
      setlanguage(data[0].languages);
      setLoyalty(data[0].loyalty_clubs);
    }
  };
  const getlist = async (e) => {
    const res = await fetch(`${Config.apiurl}hotel/loyalty/programs/view`, {
      method: "POST",
      body: JSON.stringify({
        hotel_id: hotelid,
        loyalty_program_id: programid,
      }),
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await res.json();
    if (data.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setList(data.data);
      setSelectedLoyaltynew(data.data.loyality_clubs);
      setSelectedExtrasnew(data.data.extras);
      setSelectedOffersnew(data.data.offers);
      setSelectedratenew(data.data.rates);
      setselectedMulti(data.data.multi_lang);
      setLangall(data.data.multi_lang);
    }
  };

  const Update = async () => {
    let isFormValid = true;
    const noSpecialCharRegex = /^[A-Za-z0-9\s.,-]+$/;

    const errors = {};
    if (!list.program_name) {
      isFormValid = false;
      errors.program_name = "Internal name is required!";
    }
    else if (!noSpecialCharRegex.test(list.program_name)) {
      isFormValid = false;
      errors.program_name = "Internal name should not contain this special character";
    }
    else if (list.program_name.length > 50) {
      isFormValid = false;
      errors.program_name = `Internal name cannot be more than 50 characters!`;
    }
    if (!list.condition) {
      isFormValid = false;
      errors.condition = "This field is required!";
    }
    if (list.suppliment_type !== "free") {
      if (!list.discount) {
        isFormValid = false;
        errors.discount = "This field is required!";
      }
    }
    if (list.condition !== "club") {
      if (!list.suppliment_type) {
        isFormValid = false;
        errors.suppliment_type = "This field is required!";
      }
    }
    if (list.condition !== "up_club" && list.condition !== "up_non_club") {
      if (selectedrate.length === 0) {
        isFormValid = false;
        errors.selectedrate = "Rate is required!";
      }
    }

    if (list.condition !== "up_non_club") {
      if (selectedLoyalty.length === 0) {
        isFormValid = false;
        errors.selectedLoyalty = "Level is required!";
      }
    }

    // if (list.condition !== "up_club" && list.condition !== "up_non_club") {
    //   if (selectedOffers.length === 0) {
    //     isFormValid = false;
    //     errors.selectedOffers = "Offer is required!";
    //   }
    // }

    if (list.condition === "club_extra") {
      if(extras.length>0){
      if (selectedExtras.length === 0) {
        isFormValid = false;
        errors.selectedExtras = "Add-on is required!";
      }
    }
    }
    setError(errors);

    if (isFormValid === true) {
      const formData = new FormData();
      formData.append("hotel_id", hotelid);
      formData.append("loyalty_program_id", programid);
      formData.append("title", list.program_name);
      if (list.status === true) {
        formData.append("status", 1);
      } else {
        formData.append("status", 0);
      }
      formData.append("condition", list.condition);
      if (list.condition !== "club") {
        formData.append("discount_type", list.suppliment_type);
      }
      if (list.suppliment_type !== "free") {
        formData.append("discount", list.discount);
        if (list.unit_type) {
          formData.append("discount_unit_value", list.unit_type);
        } else {
          formData.append("discount_unit_value", "currency");
        }
      }
      if (list.condition !== "up_non_club") {
        for (let i = 0; i < selectedLoyalty.length; i++) {
          formData.append(`level[${i}]`, selectedLoyalty[i].value);
        }
      }
      if (list.condition !== "up_club" && list.condition !== "up_non_club") {
        for (let i = 0; i < selectedrate.length; i++) {
          formData.append(`rate_plan[${i}]`, selectedrate[i].value);
        }
      }
      if (list.condition !== "up_club" && list.condition !== "up_non_club") {
        for (let i = 0; i < selectedOffers.length; i++) {
          formData.append(`offers[${i}]`, selectedOffers[i].value);
        }
      }
      if (list.condition === "club_extra") {
        for (let i = 0; i < selectedExtras.length; i++) {
          formData.append(`extra[${i}]`, selectedExtras[i].value);
        }
      }
      for (let i = 0; i < selectedMulti.length; i++) {
        formData.append(`multi_lang[${i}][lang_code]`, selectedMulti[i].code);
        formData.append(`multi_lang[${i}][name]`, selectedMulti[i].name);
      }
      axios
        .post(`${Config.apiurl}hotel/loyalty/programs/update`, formData, {})
        .then((res) => {
          if (res.status_code !== "200" || !res) {
            console.log("error");

            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("error");
            setToastlarge(true);
          } else {
            console.log("success");

            setToastmodal(true);
            setToastmessage(res.message);
            setToasttype("success");
            setToastlarge(true);
            setTimeout(() => navigate("/loyalty-program-list"), 3000);
          }
        });
    }
  };

  useEffect(() => {
    getlist();
    getListall();
  }, []);

  useEffect(() => {
    if (selectedratenew) {
      for (let i = 0; i < ratelist.length; i++) {
        if (selectedratenew.some((item) => item === ratelist[i].value)) {
          selectedrate.push({
            value: ratelist[i].value,
            label: ratelist[i].label,
          });
        }
      }
    }
  }, [selectedratenew, ratelist]);

  useEffect(() => {
    if (selectedLoyaltynew) {
      for (let i = 0; i < loyalty.length; i++) {
        if (selectedLoyaltynew.some((item) => item === loyalty[i].value)) {
          selectedLoyalty.push({
            value: loyalty[i].value,
            label: loyalty[i].label,
          });
        }
      }
    }
  }, [selectedLoyaltynew, loyalty]);

  // useEffect(() => {
  //   if (selectedOffersnew) {
  //     for (let i = 0; i < offers.length; i++) {
  //       if (selectedOffersnew.some((item) => item === offers[i].value)) {
  //         selectedOffers.push({
  //           value: offers[i].value,
  //           label: offers[i].label,
  //           offer_key: offers[i].offer_key,
  //         });
  //       }
  //     }
  //   }
  // }, [selectedOffersnew?.length, offers.length]);
  
  useEffect(() => {
    if (selectedOffersnew) {
      const updatedSelectedOffers = offers
        .filter((offer) => selectedOffersnew.includes(offer.value))
        .map((offer) => ({
          value: offer.value,
          label: offer.label,
          offer_key: offer.offer_key,
        }));
      
      setSelectedOffers(updatedSelectedOffers); 
    }
  }, [selectedOffersnew, offers]);

  useEffect(() => {
    if (selectedExtrasnew) {
      for (let i = 0; i < extras.length; i++) {
        if (selectedExtrasnew?.some((item) => item === extras[i].value)) {
          selectedExtras.push({
            value: extras[i].value,
            label: extras[i].label,
          });
        }
      }
    }
  }, [selectedExtrasnew?.length, extras.length,selectedExtras]);

  useEffect(() => {
    const isMatching = selectedMulti?.findIndex(
      (language) => language.code === newLang
    );
    setisMatchingLanguage(isMatching);
  }, [newLang]);

  useEffect(() => {
    let newval;

    langall?.forEach((lang) => {
      if (lang.name) {
        newval = lang.code;
      }
    });
    setNewLang(newval);
  }, [langall?.length]);

  console.log(selectedMulti);
  console.log(selectedOffers);
  

  return (
    <div>
      <Loyaltyheader />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <h5 className="">Edit Loyalty Program</h5>
            <div className="row project-wrapper">
              <div className="row dashboard-top-tab-style">
                <div className="col-xxl-12 dashboard-main-style">
                  <div className="card">
                    <div className="align-items-center d-flex">
                      <div className="flex-grow-1">
                        <div
                          className="form-check form-switch form-switch-danger form-check-inline condition-active-status"
                          dir="ltr"
                          style={{ right: "1%", float: "right", top: "20px" }}
                        >
                          <label
                            className="form-check-label"
                            htmlFor="inlineswitch5"
                            style={{
                              marginRight: "40px",
                              marginTop: "5px",
                              marginLeft: "5px",
                            }}
                          >
                            Active
                          </label>
                          <input
                            type="checkbox"
                            className="form-check-input offer_checkbox1"
                            id="inlineswitch5"
                            style={{ width: "50px", height: "25px" }}
                            value={list.status}
                            checked={list.status === true ? true : false}
                            onChange={(e) =>
                              handleloyaltyToggle(e.target.checked, "status")
                            }
                          />
                        </div>
                      </div>
                      <div style={{ float: "right", paddingRight: "15px" }}>
                      <Link
                          type="button"
                          className="btn db-other-button"
                          to="/loyalty-program-list"
                          style={{ position:"relative",top:"15px"}}
                        >
                          Loyalty Program List
                        </Link>
                        <Link
                          type="button"
                          to="#"
                          className="btn loyalty_prgm_save_new"
                          onClick={Update}
                        >
                          {" "}
                          Update
                        </Link>
                        {/* <Link
                          type="button"
                          to="#"
                          className="btn db-save-button"
                          style={{ height: "30px", top: "18px" }}
                          onClick={Update}
                        >
                          {" "}
                          Update
                        </Link> */}
                      </div>
                    </div>
                    <div
                      className="form-translation-loyaly_prgrm"
                      style={{ marginLeft: "16px" }}
                    >
                      <ul>
                        <li
                          className={tab === "EN" ? "active" : ""}
                          onClick={(e) => setTab("EN")}
                        >
                          EN
                        </li>
                        <li
                          className={tab === "TRANS" ? "active" : ""}
                          onClick={(e) => setTab("TRANS")}
                        >
                          Translations
                        </li>
                      </ul>
                    </div>
                    <div className="card-body">
                      <div className="live-preview">
                        <form action="#">
                          <div
                            className="be_loyalty_level"
                            style={{ bottom: "42px" }}
                          >
                            {tab === "EN" ? (
                              <div className="row">
                                <div
                                  class="col-lg-6"
                                  style={{ marginTop: "15px" }}
                                >
                                  <div className="input-group">
                                    <label
                                      className="input-group-text input-joint-color"
                                      id="inputGroup-sizing-default"
                                    >
                                      Internal Name
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      name="program_name"
                                      value={list.program_name}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "program_name",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </div>
                                  <label style={{ color: "red" }}>
                                    {formerror.program_name}
                                  </label>
                                </div>
                                <div
                                  className="col-lg-6"
                                  style={{ marginTop: "15px" }}
                                >
                                  <span className="input-group">
                                    <span
                                      className="input-group-text input-joint-color1"
                                      id="inputGroup-sizing-default"
                                    >
                                      Use Case{" "}
                                      <span className="form-validation-required">
                                        *
                                      </span>
                                    </span>
                                    <select
                                      className="form-control form-select"
                                      id="idPayment"
                                      value={list.condition}
                                      onChange={(e) =>
                                        handleConditionChange(
                                          "condition",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="">Select...</option>
                                      <option value="club">Club</option>
                                      <option value="up_club">
                                        Upselling - Club Members
                                      </option>
                                      <option value="up_non_club">
                                        Upselling - Non-club Members
                                      </option>
                                      <option value="club_extra">
                                        Club - Extras
                                      </option>
                                    </select>
                                  </span>
                                  <label style={{ color: "red" }}>
                                    {formerror.condition}
                                  </label>
                                </div>
                                {list.condition ? (
                                  <>
                                    {list.condition !== "up_non_club" ? (
                                      <div
                                        class="col-lg-6"
                                        style={{ marginTop: "15px" }}
                                      >
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                            style={{ width: "90px" }}
                                          >
                                            {" "}
                                            Levels
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <div className="col-md-6">
                                            <MultiSelect
                                              options={loyalty}
                                              value={selectedLoyalty}
                                              onChange={(e) =>
                                                setSelectedLoyalty(e)
                                              }
                                              labelledBy="Select"
                                              selectionType="counter"
                                              className="multiselect_loyalty_prgrm"
                                              allowCustomValue={true}
                                              aria-expanded="true"
                                            />
                                          </div>
                                        </div>
                                        <label style={{ color: "red" }}>
                                          {formerror.selectedLoyalty}
                                        </label>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {list.condition !== "club" ? (
                                      <div
                                        className="col-lg-6"
                                        style={{ marginTop: "15px" }}
                                      >
                                        <span className="input-group">
                                          <span
                                            className="input-group-text input-joint-color1"
                                            id="inputGroup-sizing-default"
                                          >
                                            Calculation Type{" "}
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </span>
                                          <select
                                            className="form-control form-select"
                                            data-choices
                                            data-choices-search-false
                                            id="idPayment"
                                            name="suppliment_type"
                                            value={list.suppliment_type}
                                            onChange={(e) =>
                                              handleConditionChange(
                                                "suppliment_type",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">Select...</option>
                                            <option value="free">
                                              Free Supplement
                                            </option>
                                            <option value="with_discount">
                                              Supplement with discount
                                            </option>
                                          </select>
                                        </span>
                                        <label style={{ color: "red" }}>
                                          {formerror.suppliment_type}
                                        </label>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {list.suppliment_type !== "free" ? (
                                      <div
                                        className="col-lg-6"
                                        style={{ marginTop: "15px" }}
                                      >
                                        <span
                                          className="input-group"
                                          style={{ width: "450px" }}
                                        >
                                          <span
                                            className="input-group-text input-joint-color1"
                                            id="inputGroup-sizing-default"
                                          >
                                            Variation Calculation{" "}
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </span>
                                          <input
                                            type="number"
                                            className="form-control"
                                            aria-label="Sizing example input"
                                            aria-describedby="inputGroup-sizing-default"
                                            name="discount"
                                            value={list.discount}
                                            onChange={(e) =>
                                              handleConditionChange(
                                                "discount",
                                                e.target.value
                                              )
                                            }
                                            onFocus={(e) =>
                                              e.target.addEventListener(
                                                "wheel",
                                                function (e) {
                                                  e.preventDefault();
                                                },
                                                { passive: false }
                                              )
                                            }
                                          />
                                          <span
                                            className="rate_change_section"
                                            style={{ width: "110px" }}
                                          >
                                            <select
                                              id="ForminputState"
                                              className="form-select"
                                              style={{ marginLeft: "105px" }}
                                              name="unit_type"
                                              value={list.unit_type}
                                              onChange={(e) =>
                                                handleConditionChange(
                                                  "unit_type",
                                                  e.target.value
                                                )
                                              }
                                            >
                                              <option value="currency" selected>
                                                {currency.code}
                                              </option>
                                              <option value="percentage">
                                                %
                                              </option>
                                            </select>
                                          </span>
                                        </span>
                                        <label style={{ color: "red" }}>
                                          {formerror.discount}
                                        </label>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {list.condition !== "up_club" &&
                                    list.condition !== "up_non_club" ? (
                                      <div
                                        className="col-lg-6"
                                        style={{ marginTop: "15px" }}
                                      >
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                          >
                                            Applied Rates{" "}
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <div className="col-md-6">
                                            <MultiSelect
                                              options={ratelist}
                                              value={selectedrate}
                                              onChange={(e) =>
                                                setSelectedrate(e)
                                              }
                                              labelledBy="Select"
                                              selectionType="counter"
                                              className="multiselect_loyalty_rate"
                                              allowCustomValue={true}
                                            />
                                          </div>
                                        </div>
                                        <label style={{ color: "red" }}>
                                          {formerror.selectedrate}
                                        </label>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    {list.condition !== "up_club" &&
                                    list.condition !== "up_non_club" ? (
                                      <>
                                      {offers.length>0?
                                      <div
                                        className="col-lg-6"
                                        style={{ marginTop: "15px" }}
                                      >
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                          >
                                            Offers
                                          </label>
                                          <div className="col-md-6">
                                            <MultiSelect
                                              options={offers}
                                              value={selectedOffers}
                                              onChange={(val) => setSelectedOffers(val)}
                                              labelledBy="Select"
                                              selectionType="counter"
                                              className="multiselect_loyalty_offer"
                                              allowCustomValue={true}
                                            />
                                          </div>
                                        </div>
                                        {/* <label style={{ color: "red" }}>
                                          {formerror.selectedOffers}
                                        </label> */}
                                      </div>
                                      :""}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {list.condition !== "club" &&
                                    list.condition !== "up_club" &&
                                    list.condition !== "up_non_club" ? (
                                      <>
                                      {extras.length>0?
                                      <div
                                        className="col-lg-6"
                                        style={{ marginTop: "15px" }}
                                      >
                                        <div className="input-group">
                                          <label
                                            className="input-group-text input-joint-color"
                                            id="inputGroup-sizing-default"
                                          >
                                            Add-ons
                                            <span className="form-validation-required">
                                              *
                                            </span>
                                          </label>
                                          <div className="col-md-6">
                                            <MultiSelect
                                              options={extras}
                                              value={selectedExtras}
                                              onChange={(e) =>
                                                setSelectedExtras(e)
                                              }
                                              labelledBy="Select"
                                              selectionType="counter"
                                              className="multiselect_loyalty_offer"
                                              allowCustomValue={true}
                                            />
                                          </div>
                                        </div>
                                        <label style={{ color: "red" }}>
                                          {formerror.selectedExtras}
                                        </label>
                                      </div>
                                      :""}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            ) : (
                              <>
                                <div
                                  className="col-md-12"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="col-lg-6">
                                    <div className="d-flex">
                                      <label
                                        className="input-group-text input-joint-color"
                                        id="inputGroup-sizing-default"
                                        style={{
                                          width: "130px",
                                          borderBottomRightRadius: "0px",
                                          borderTopRightRadius: "0px",
                                        }}
                                      >
                                        Add/edit in
                                      </label>
                                      <select
                                        className="form-control"
                                        data-choices
                                        data-choices-search-false
                                        name="choices-single-default"
                                        id="idPayment"
                                        style={{
                                          width: "130px",
                                          height: "38px",
                                          marginLeft: "-1px",
                                        }}
                                        value={newLang}
                                        onChange={(e) =>
                                          handleLanguageChangenew(
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">
                                          Choose language
                                        </option>

                                        {language.map((lang, inx) => {
                                          return (
                                            <option key={inx} value={lang.code}>
                                              {lang.label}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                  <div
                                    className="appendmore"
                                    style={{ marginTop: "7px" }}
                                  ></div>
                                  {newLang ? (
                                    selectedMulti.length > 0 ? (
                                      <>
                                        {selectedMulti.some(
                                          (item) => item.code === newLang
                                        ) ? (
                                          <div
                                            className="col-md-12 translation-fill-section"
                                            data-value="1"
                                          >
                                            <div className="col-lg-6">
                                              <div className="input-group">
                                                <label
                                                  className="input-group-text input-joint-color"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  Internal Name
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={
                                                    selectedMulti[
                                                      isMatchingLanguage
                                                    ]?.name
                                                  }
                                                  onChange={(e) =>
                                                    handleloyallangChange(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : (
                                          <div
                                            className="col-md-12 translation-fill-section"
                                            data-value="1"
                                          >
                                            <div className="col-lg-12">
                                              <div className="input-group">
                                                <label
                                                  className="input-group-text input-joint-color"
                                                  id="inputGroup-sizing-default"
                                                >
                                                  Internal Name
                                                </label>
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder=""
                                                  value={
                                                    selectedMulti[
                                                      selectedMulti?.length - 1
                                                    ]?.name
                                                  }
                                                  onChange={(e) =>
                                                    handleloyallangChange(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </>
                            )}
                          </div>

                          <div className="padding-20"></div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {toastmodal === true ? (
          <Toastmodal
            setToast={setToastmodal}
            message={toastmessage}
            type={toasttype}
            large={toastlarge}
          />
        ) : (
          ""
        )}
        <Footer />
      </div>
    </div>
  );
}

export default Loyaltyprogramedit;
