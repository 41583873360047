import React, { useState } from "react";
import { Plus, Trash2, Edit2 } from "lucide-react";
import Commonheader from "../../Common/Commonheader";

const initialItems = [
  { id: 1, title: "Revenue Management System (RMS)", url:"https://extranet.lybra.tech/hrfrc/login" },
  { id: 2, title: "Premium Revenue Analysis & Management", url:"https://metahotels.dms.fornova.com/login" },
  { id: 3, title: "Upsell Voucher Engine (Gift Vouchers)", url:"https://giftvouchers.thedirectrate.com" },
  { id: 4, title: "Iceportal - GDS", url:"https://iceportal.shijigroup.com/login"},
  { id: 5, title: "Central Reservation Office (CRO)", url:" https://cro.thedirectrate.com" },
  { id: 6, title: "Online Reputation Tool", url:"https://guest-experience.com/users/sign_in" },
  { id: 7, title: "AI Hotel Chat Bot", url:"https://chatbot.thedirectrate.com" },
  { id: 8, title: "Central Reservation System (CRS)", url:"https://crs.thedirectrate.com/" },
  { id: 9, title: "Customer Relationship Management (CRM)", url:" https://crm.metahotels.ae/" },
  { id: 10, title: "Magic Parity Plus & Price Match Tool", url:"https://hotelmonitor.info/backend" },
];

const RibbonMenu = () => {
  const [items, setItems] = useState(initialItems);

  const addNewRibbon = () => {
    const newId = items.length + 1;
    setItems([...items, { id: newId, title: `New Option ${newId}` }]);
  };

  const removeRibbon = (id) => {
    setItems(items.filter((item) => item.id !== id));
  };

  return (
    <div>
      <Commonheader />
      <div className="container ma_top_W" style={{marginBottom:"5%"}}>
        <div className="max-w-3xl mx-auto p-8 bg-white ma_bottom_W ">
          {/* New header section */}
          <div className="header-section mb-6">
            <div className="col-sm d-flex justify-content-between align-items-center ma_padding_W">
              <h5 className="text-teal-600 font-medium teal">DR SUITE</h5>
              <div className="flex-shrink-0">
                <button onClick={addNewRibbon} className="btn db-save-button">
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* <style>
            {`
              
            `}
          </style> */}
        <div className="container ma_left_W">
          <div className="ribbon-container">
            {items.map((item, index) => (
              <div key={item.id} className="ribbon_W-container">
                <div className="ribbon_W">
                  <div className="number-section">
                    <div className="number-content">
                      {item.id.toString().padStart(2, "0")}
                      <div className="twisted-edge"></div>
                      <div className="white-edge"></div>
                    </div>
                  </div>
                  <div className="content-section-nw">
                  <div className="content-wrapper_W">
                  <a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="content-inner_W"
                  >
                    {item.title}
                    <div className="action-buttons">
                      <button className="action-button">
                        <Edit2 color="white" size={16} />
                      </button>
                      <button
                        onClick={() => removeRibbon(item.id)}
                        className="action-button"
                      >
                        <Trash2 color="white" size={16} />
                      </button>
                    </div>
                  </a>
                </div>

                    {/* <div className="content-wrapper_W">
                      <div className="content-inner_W">
                        {item.title}
                        <div className="action-buttons">
                          <button className="action-button">
                            <Edit2 color="white" size={16} />
                          </button>
                          <button
                            onClick={() => removeRibbon(item.id)}
                            className="action-button"
                          >
                            <Trash2 color="white" size={16} />
                          </button>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RibbonMenu;
