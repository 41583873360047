import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Date from "../Common/Date";
import Footer from "../Common/Footer";
import Overviews from "../Common/Overviews";
import Commonheader from "../Common/Commonheader";
import { useAuthHandling } from "../Common/useAuthHandling";
import Config from "../Config";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import Toastmodal from "../Common/Toastmodal";
import Select from "react-select";
import Loading from "../Loading";
import { UserContext } from "../Contexts/UserContext";

function Dashboard() {
  let navigate = useNavigate();
  const { updateHotel_select, hotel_selectiddash} =useContext(UserContext);

  const storedData = localStorage.getItem("allhotel");
  let store = [];
  
  try {
    store = storedData ? JSON.parse(storedData) : [];
  } catch (error) {
    console.error("Invalid JSON in localStorage for allhotel:", error);
  }
  
  const [hotel_select, setHotel_select] = useState(store ? store : hotel_selectiddash || []);

  // const [hotel_select, setHotel_select] = useState(() => {
   
  //   return storedData ? JSON.parse(storedData) : hotel_selectiddash;
  // });


  const [hotel_selectid, setHotel_selectid] = useState([]);
  const [averageroomrate, setAverageroomrate] = useState([]);
  const { isAuthorized, handleUnauthorized } = useAuthHandling();
  const [level, setLevel] = useState([]);
  const [cancelleddata, setCancelleddata] = useState([]);
  const [originalreservation, setOriginalreservation] = useState([]);
  const [totalreservation, setTotalreservation] = useState([]);
  const [upselldata, setUpselldata] = useState([]);
  const [list, setList] = useState([]);
  const [listall, setListall] = useState([]);

  const [dateRange, setDateRange] = useState([]);
  const [toastmodal, setToastmodal] = useState(false);
  const [toastmessage, setToastmessage] = useState("");
  const [toasttype, setToasttype] = useState("");
  const [toastlarge, setToastlarge] = useState(false);
  const [isDefaultRange, setIsDefaultRange] = useState(true);
  const [currency, setCurrency] = useState([]);
  const [selectedcurrency,setSelectedcurrency]=useState({});
  const [country, setCountry] = useState([]);
  const [currencycode, setCurrencycode] = useState([]);
  const [loader, setLoader] = useState(false);


  const [ip, setIp] = useState("");
  const [countrycode, setCountrycode] = useState("");


  const handleCalendarChange = (selectedDates) => {
    if (selectedDates.length === 2) {
      setDateRange(selectedDates);
      setIsDefaultRange(false);
      Search(selectedDates); 
    }
  };

  const handleCalendarIconClick = (calendarId) => {
    const calendar = document.querySelector(`#flatpickr-${calendarId}`);
    if (calendar) {
      const isCalendarOpen = calendar._flatpickr.isOpen;
      const icon = document.querySelector(`#calendar-icon-${calendarId}`);
      if (icon) {
        icon.style.display = isCalendarOpen ? "none" : "inline-block";
      }
      calendar._flatpickr[isCalendarOpen ? "close" : "open"]();
    }
  };

  const Search = async (selectedDates) => {

    const fromDate = moment(selectedDates[0]).format("DD-MM-YYYY");
    const untilDate = moment(selectedDates[1]).format("DD-MM-YYYY");

      const res = await fetch(
        `${Config.apiurl}reservation-list/search`,
        {
          method: "POST",
          body: JSON.stringify({
            hotels: hotel_selectid,
            from:fromDate,
            until:untilDate
          }),
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        if (data.status_code !== "200" || !data.data) {
          console.log("error");
        } else {
          // setAverageroomrate(data?.data[0].average_room_rate);
          // setLevel(data?.data[0].level_array);
          // setCancelleddata(data.data[0].cancelled_reservation_rate);
          // setOriginalreservation(data.data[0].original_reservation_rate);
          // setTotalreservation(data.data[0].reservation_rate);
          // setUpselldata(data.data[0].upsell_reservation_rate);
          setList(data.data[0]);
          setListall(data.data[0]);
          setLoader(true);

        }
      } else if (res.status === 401) {
        handleUnauthorized();
        Search();
      } else {
        console.log("error");
      }
  };
  
  const getData = async (e) => {
    if(hotel_selectid.length>0){
      const res = await fetch(`${Config.apiurl}reservation-list`, {
        method: "POST",
        body: JSON.stringify({
          hotels: hotel_selectid,
        }),
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      if (res.status === 200) {
        const data = await res.json();

        if (data.status_code === "200") {
          setLoader(true);

          // setAverageroomrate(data?.data[0].average_room_rate);
          // setLevel(data?.data[0].level_array);
          // setCancelleddata(data.data[0].cancelled_reservation_rate);
          // setOriginalreservation(data.data[0].original_reservation_rate);
          // setTotalreservation(data.data[0].reservation_rate);
          // setUpselldata(data.data[0].upsell_reservation_rate);
          setList(data.data[0]);
          setListall(data.data[0]);

        } else if (data.reauth === true) {
          handleUnauthorized();
          getData();
        } else {
          console.log("error");
        }
      } else if (res.status === 401) {
        handleUnauthorized();
        getData();
      } else {
        console.log("error");
      }
  }
  };


  useEffect(() => {
    if(store.length>0 ||hotel_selectiddash.length>0){
    if (hotel_select.length === 0 && !sessionStorage.getItem("redirected")) {
      sessionStorage.setItem("redirected", "true");
      window.location.href = "/dashboard";
    }
  }
  }, );

  useEffect(() => {
    console.log("kkk");
    if (hotel_select) {
      console.log("llll");
      console.log(hotel_select);
      let idd = [];
      for (let i = 0; i < hotel_select.length; i++) {
        if (
          hotel_select[i]?.isGroupChild &&
          hotel_select[i]?.isGroupChild === true
        ) {
          idd.push(hotel_select[i].value);
        } else if (
          !hotel_select[i]?.isGroup &&
          !hotel_select[i]?.isGroupChild
        ) {
          idd.push(hotel_select[i].value);
        }
      }
      setHotel_selectid(idd);
    }
  }, [hotel_select]);


  useEffect(() => {
    getData();
  }, [hotel_selectid]);

  useEffect(() => {
    const startOfMonth = moment().startOf('month').toDate();
    
    const today = moment().toDate();
  
    setDateRange([startOfMonth, today]);
  }, []);


  function processDBValue(dbValue) {
    if (dbValue === null || dbValue === undefined) {
      return "";
    }
    
    dbValue = dbValue.toString();
    let numericValue = parseFloat(dbValue.replace(/,/g, ""));
    
    let result = numericValue.toFixed(2);
    
    return result;
  }

  const currentMonthDays = () => {
    return moment().date(); 
  };

  const getcurrency = async (e) => {

    const res = await fetch(`${Config.apiurl}currencies-select`, {
      method: "GET",
     
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setCurrency(data);
    }
  }

  const getcountry = async (e) => {

    const res = await fetch(`${Config.apiurl}countries`, {
      method: "GET",
     
    });
    const data1 = await res.json();
    const data = data1.data;

    if (data1.status_code !== "200" || !data) {
      console.log("error");
    } else {
      setCountry(data);
    }
  }

  useEffect(()=>{
    getcurrency();
    getcountry();
  },[])


  useEffect(() => {
    if (country && countrycode) {
      for (let i = 0; i < country.length; i++) {
        if (country[i].alpha_code2 === countrycode) {
          if (Object.keys(selectedcurrency).length === 0) {
           setSelectedcurrency(country[i].currencydata);
           setCurrencycode(country[i].currencydata.code);
           sessionStorage.setItem("dash_select_currency", JSON.stringify(country[i].currencydata)); 
           sessionStorage.setItem("dash_select_currency_code", country[i].currencydata.code); 
       
          }

        }
      }
    }
  }, [country.length, countrycode, selectedcurrency]);

  function handleselect(data){
    setSelectedcurrency(data);
    setCurrencycode(data.code);

    sessionStorage.setItem("dash_select_currency", JSON.stringify(data)); 
    sessionStorage.setItem("dash_select_currency_code", data.code); 
  }

  function processDBValuenew(dbValue) {
    // Ensure dbValue is not null or undefined
    if (dbValue === null || dbValue === undefined) {
      return "";
    }
    dbValue = dbValue.toString();
    const numericValue = parseFloat(dbValue.replace(/,/g, ""));

    const result = numericValue !== 0 ? numericValue.toFixed(2) : dbValue;

    return result.endsWith(".00") ? result.slice(0, -3) : result;
  }

  useEffect(()=>{
    if(listall.average_room_rate){
      let av=listall.average_room_rate;
      let cance=listall.cancelled_reservation_rate
      let or=listall.original_reservation_rate
      let up=listall.upsell_reservation_rate
      let to=listall.reservation_rate
      let average=0;
      let cancel=0;
      let orginal=0;
      let upsell=0;
      let total=0;
        const fetchPromises = av.map(async (room) => {
          if (currencycode) {
              const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
              
              try {
                  const response = await fetch(url);
                  const data = await response.json();

                  if (Object.hasOwn(data.rates, currencycode)) {
                      const exchangeRate = data.rates[currencycode];
                    
                      return processDBValuenew(room.rate) * exchangeRate;
                  } else {
                      throw new Error("Exchange rate not found");
                  }
              } catch (error) {
                  console.error("Error:", error);
                  return 0;  // If there's an error, return 0 for this rate
              }
          }
          return 0;
      });

      // Wait for all fetches to complete and then calculate the total average
      Promise.all(fetchPromises).then((results) => {
          // Sum up all the converted rates
          average = results.reduce((acc, val) => acc + val, 0);
          setAverageroomrate(average);
      });

      const fetchPromises1 = cance.map(async (room) => {
        if (currencycode) {
            const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
            
            try {
                const response = await fetch(url);
                const data = await response.json();

                if (Object.hasOwn(data.rates, currencycode)) {
                    const exchangeRate = data.rates[currencycode];
                    return processDBValuenew(room.rate) * exchangeRate;
                } else {
                    throw new Error("Exchange rate not found");
                }
            } catch (error) {
                console.error("Error:", error);
                return 0;  // If there's an error, return 0 for this rate
            }
        }
        return 0;
    });

    // Wait for all fetches to complete and then calculate the total average
    Promise.all(fetchPromises1).then((results) => {
        // Sum up all the converted rates
        cancel = results.reduce((acc, val) => acc + val, 0);
        setCancelleddata(cancel);
    });



    const fetchPromises2 = or.map(async (room) => {
      if (currencycode) {
          const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
          
          try {
              const response = await fetch(url);
              const data = await response.json();

              if (Object.hasOwn(data.rates, currencycode)) {
                  const exchangeRate = data.rates[currencycode];
                  return processDBValuenew(room.rate) * exchangeRate;
              } else {
                  throw new Error("Exchange rate not found");
              }
          } catch (error) {
              console.error("Error:", error);
              return 0;  // If there's an error, return 0 for this rate
          }
      }
      return 0;
  });

  // Wait for all fetches to complete and then calculate the total average
  Promise.all(fetchPromises2).then((results) => {
      // Sum up all the converted rates
      orginal = results.reduce((acc, val) => acc + val, 0);
      setOriginalreservation(orginal);
  });

  const fetchPromises3 = up.map(async (room) => {
    if (currencycode) {
        const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
        
        try {
            const response = await fetch(url);
            const data = await response.json();

            if (Object.hasOwn(data.rates, currencycode)) {
                const exchangeRate = data.rates[currencycode];
                return processDBValuenew(room.rate) * exchangeRate;
            } else {
                throw new Error("Exchange rate not found");
            }
        } catch (error) {
            console.error("Error:", error);
            return 0;  // If there's an error, return 0 for this rate
        }
    }
    return 0;
});

// Wait for all fetches to complete and then calculate the total average
Promise.all(fetchPromises3).then((results) => {
    // Sum up all the converted rates
    upsell = results.reduce((acc, val) => acc + val, 0);
    setUpselldata(upsell);
});

const fetchPromises4 = to.map(async (room) => {
  if (currencycode) {
      const url = `https://widgets.thedirectrate.com/api/currencyconvert?hotelcurrency=${room.currency}`;
      
      try {
          const response = await fetch(url);
          const data = await response.json();

          if (Object.hasOwn(data.rates, currencycode)) {
              const exchangeRate = data.rates[currencycode];
              return processDBValuenew(room.rate) * exchangeRate;
          } else {
              throw new Error("Exchange rate not found");
          }
      } catch (error) {
          console.error("Error:", error);
          return 0;  // If there's an error, return 0 for this rate
      }
  }
  return 0;
});

// Wait for all fetches to complete and then calculate the total average
Promise.all(fetchPromises4).then((results) => {
  // Sum up all the converted rates
  total = results.reduce((acc, val) => acc + val, 0);
  setTotalreservation(total);
});

  
    }
  },[listall,currencycode])


  console.log(hotel_select);
  console.log(hotel_selectid);
  console.log(hotel_selectiddash);
  console.log(store);
  return (
    <>
      <div id="layout-wrapper">
        <Commonheader />

        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row project-wrapper">
                  
                  <div className="row dashboard-top-tab-style dashboard-top-tab-style_new">
                  <div className=" col-xl-6 col-lg-5 dashboard-main-style">
                    <div className="col-xxl-8">
                      <Overviews setIp={setIp} setCountrycode={setCountrycode} ip={ip} setSelectedcurrency={setSelectedcurrency} setCurrencycode={setCurrencycode}
           />
                    </div>
                    
                  </div>

                  <div className="col-xl-3 col-lg-4 col-sm-6 dashboard_select_full">
                  <label class="input-group-tex" for="inputGroupSelect01">Currency</label>
                  <Select
                      value={selectedcurrency}
                      onChange={(data)=>handleselect(data)}
                      options={currency}
                      name="choices-single-default"
                      id="idStatus"
                      className="form-control dashboard_select"
                  ></Select>
                  </div>
                  
                <div className="  col-xl-3 col-lg-3 col-sm-6 form-calender-add">
                    <form action="#">
                      <div className="input-group">
                        <Flatpickr
                          className="form-control dash-filter-picker shadow fs-13 form-control flatpickr-input active"
                          placeholder="Choose date"
                          id="flatpickr-from"
                          options={{
                            mode: "range",
                            dateFormat: "d M, Y",
                          }}
                          value={dateRange}
                          onChange={handleCalendarChange}
                        />
                        
                        <span
                          className="dashboard_calendar_new fon mdi mdi-calendar-month-outline"
                          onClick={() =>handleCalendarIconClick("from")}></span>
                      </div>
                    </form>
                  </div>
                </div>
                {loader===false?
                <Loading/>
                :
                <div className="row dashboard-top-tab-style">
                  <div class="col-xl-9 dashboard-main-style">
                    <div class="row">
                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #A48BCF" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  INITIAL RESERVATIONS/{" "}
                                  <span className="rev-plus">+</span> REVENUE
                                </p>
                                <div class="overview-today-sec">
                                {isDefaultRange && (
                                  <p class="dashboard-date-style">Last {currentMonthDays()} days</p>
                                )}
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-danger rounded fs-3">
                                      <i class="bx bx-calendar-x text-danger"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-18 fw-semibold ff-secondary mb-2">
                                {list.total_reservation_count}
                                <span class="value-specify-span">
                                  Reservations
                                </span>
                                / <span className="rev-plus">+</span> {currencycode}{" "}
                                {totalreservation?
                                <span>{processDBValue(totalreservation)}</span>
                                :"0"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #E06F95" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  CANCELLATIONS /
                                  <span className="rev-minus"> -</span> REVENUE
                                </p>
                                <div class="overview-today-sec">
                                {isDefaultRange && (
                                  <p class="dashboard-date-style">Last {currentMonthDays()} days</p>
                                )}
                                <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-danger rounded fs-3">
                                      <i class="bx bx-calendar-x text-danger"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-18 fw-semibold ff-secondary mb-2">
                                {list.cancelled_reservation_count}
                                <span class="value-specify-span">
                                  Cancellations
                                </span>
                                / <span className="rev-minus"> -</span> {currencycode}{" "}
                                {cancelleddata?
                                <span>{processDBValue(cancelleddata)}</span>
                                :"0"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #E5A43D" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  ROOM NIGHTS /ARR
                                </p>
                                <div class="overview-today-sec">
                                {isDefaultRange && (
                                  <p class="dashboard-date-style">Last {currentMonthDays()} days</p>
                                )}
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-info rounded fs-3">
                                      <i class="bx bxs-edit text-info"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-18 fw-semibold ff-secondary mb-2">
                                {list.original_room_nights}
                                <span class="value-specify-span">
                                  Room Nights
                                </span>
                                /{" "}{currencycode}
                                {averageroomrate?
                                <span> {processDBValue(averageroomrate)}</span>
                                :" 0"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #EBCF54" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  UPSELLS /<span className="rev-plus">+</span>{" "}
                                  REVENUE
                                </p>
                                <div class="overview-today-sec">
                                {isDefaultRange && (
                                  <p class="dashboard-date-style">Last {currentMonthDays()} days</p>
                                )}
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-success rounded fs-3">
                                      <i class="bx bxs-edit text-success"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                            <h4 class="fs-18 fw-semibold ff-secondary mb-2">
                                {list.upsell_reservation_count}<span class="value-specify-span">Upsells</span>
                                / <span className="rev-plus">+</span> {currencycode} 
                                {upselldata?
                                <span> {processDBValue(upselldata)}</span>
                                :" 0"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #81C784" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  ORIGINAL RESERVATIONS /
                                  <span className="rev-plus">+</span> REVENUE
                                </p>
                                <div class="overview-today-sec">
                                {isDefaultRange && (
                                  <p class="dashboard-date-style">Last {currentMonthDays()} days</p>
                                )}
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-success rounded fs-3">
                                      <i class="bx bxs-edit text-success"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-18 fw-semibold ff-secondary mb-2">
                                {list.original_reservation_count}
                                <span class="value-specify-span">
                                  Reservations
                                </span>
                                / <span className="rev-plus">+</span> {currencycode}{" "}
                                {originalreservation?
                                <span>{processDBValue(originalreservation)}</span>
                                :"0"}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #95C3BF" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  RETRIEVED RESERVATIONS /{" "}
                                  <span className="rev-plus">+</span> REVENUE
                                </p>
                                <div class="overview-today-sec">
                                  <p class="dashboard-date-style">Today</p>
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-success rounded fs-3">
                                      <i class="bx bxs-edit text-success"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-18 fw-semibold ff-secondary mb-2">
                                0
                                <span class="value-specify-span">
                                  Reservations
                                </span>
                                / <span className="rev-plus">+</span> AED 0
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #8DB6D8" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  DISPARITY /
                                  <span className="rev-minus"> -</span> REVENUE
                                </p>
                                <div class="overview-today-sec">
                                  <p class="dashboard-date-style">Today</p>
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-danger rounded fs-3">
                                      <i class="bx bxs-edit text-danger"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-18 fw-semibold ff-secondary mb-2">
                                0
                                <span class="value-specify-span">
                                  Disparities
                                </span>
                                / <span className="rev-minus"> -</span> AED 0
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #BCBF64" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  COMMISSION / HOTEL
                                </p>
                                <div class="overview-today-sec">
                                  <p class="dashboard-date-style">Today</p>
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-info rounded fs-3">
                                      <i class="bx bxs-edit text-info"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="align-items-end justify-content-between mt-2">
                              <h4 class="fs-18 fw-semibold ff-secondary mb-2">
                                AED 0/ 0
                                <span class="value-specify-span">Hotels</span>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div> */}

                      <div class="col-xl-4 col-md-4">
                        <div class="card card-animate">
                          <div
                            class="card-body"
                            style={{ borderTop: "4px solid #F0E687" }}
                          >
                            <div class="align-items-end justify-content-between">
                              <div class="flex-grow-1 overflow-hidden">
                                <p class="text-uppercase fw-medium db-title-color text-truncate mb-0">
                                  LOYALTY
                                </p>
                                <div class="overview-today-sec">
                                {isDefaultRange && (
                                  <p class="dashboard-date-style">Last {currentMonthDays()} days</p>
                                )}
                                  <div class="flex-shrink-0 icon_body dashboard-date-icon">
                                    <span class="avatar-title bg-soft-danger rounded fs-3">
                                      <i class="bx bxs-edit text-danger"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex mt-2">
                              <h4 class="fs-18 fw-semibold ff-secondary mb-2">
                               {list.total_level_count}
                              </h4>
                              <p class="box-expand-content">
                              {level.map((data, i) => {
                                return (
                                  <>
                                    <span class="loyalty-base">{data.level} - {data.count}</span>
                                  </>
                                  );
                                })}
                              </p>
                            </div>
                            {/* <div class="d-flex align-items-end justify-content-between mt-2">
                            <h4 class="fs-18 fw-semibold ff-secondary mb-2">
                               {list.total_level_count}
                              </h4>
                              <p class="box-expand-content">
                              {level.map((data, i) => {
                                return (
                                  <>
                                    <span class="loyalty-base">{data.level} - {data.count}</span>
                                  </>
                                  );
                                })}
                              </p>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 dashboard-main-style">
                    <div class="row">
                      <div class="col-xl-12 col-md-12">
                        <div class="card">
                          <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1">
                              Latest News
                            </h4>
                          </div>
                          <div class="card-body">
                            <h5 class="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p class="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. Read more..
                            </p>
                            <h5 class="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p class="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. Read more..
                            </p>
                            <h5 class="news-head">
                              Discover the new “Price comparison and Parity”
                              module integrated in our Booking Engine
                            </h5>
                            <p class="news-content">
                              Display the prices of the main OTAs without the
                              need to leave your website. In addition, Read
                              more..
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          </div>
          {toastmodal === true ? (
        <Toastmodal
          setToast={setToastmodal}
          message={toastmessage}
          type={toasttype}
          large={toastlarge}
        />
      ) : (
        ""
      )}
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
