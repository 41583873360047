import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Config from "../Config";
import Alerts from "../Alerts";
import Loading from "../Loading";
import Cookies from "js-cookie";
import { UserContext } from "../Contexts/UserContext";
import LZString from "lz-string";
import CryptoJS from "crypto-js";
import { Spinner } from "reactstrap";

function Verify() {
  let navigate = useNavigate();

  const location = useLocation();
  const exampleParameter = location.state || {};
  const { updateHotel_select, hotel_select, languageid, updatelanguage } =
    useContext(UserContext);

  const email = localStorage.getItem("email");
  const pass = localStorage.getItem("pass");

  const [otp, setOtp] = useState("");
  const [alert, setAlert] = useState(false);
  const [formerror, setError] = useState({});
  const [alertmessage, setAlertmessage] = useState("");
  const [alerttype, setAlerttype] = useState("");
  const [languagedata, setLanguagedata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [logindetails, setLogindetails] = useState([]);
  const [button_disable, setButton_disable] = useState(false);

  const secretKey = "fTh76vpd&nxs#94PzAr49Hy6q*!MbLR7";

  var arr = email.split("@");
  const v1 =
    arr[0][0] +
    arr[0][1] +
    "*".repeat(arr[0].length - 2) +
    "@" +
    arr[1][0] +
    "*".repeat(arr[1].length - 2) +
    arr[1][arr[1].length - 1];

  const validate1 = (values) => {
    const errors = {};
    if (!values) {
      errors.otp = "Otp is required!";
    }
    return errors;
  };

  const getinfo = async (e) => {
    if (languageid === null || undefined) {
      const res = await fetch(`${Config.apiurl}get-page-contents/verify_otp`, {
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status === 404 || !data) {
        console.log("error");
      } else {
        setLanguagedata(data.data);
        setLoading(true);
      }
    } else {
      const res = await fetch(`${Config.apiurl}get-page-contents/verify_otp`, {
        method: "POST",
        body: JSON.stringify({
          language: languageid,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await res.json();
      if (data.status === 404 || !data) {
        console.log("error");
      } else {
        setLanguagedata(data.data);
        setLoading(true);
      }
    }
  };

  const handleButtonClick = () => {
    setButton_disable(true);
    Verify(); 
  };


  const Verify = async () => {
    const ress1 = validate1(otp);

    setError(ress1);

    if (exampleParameter === "forgot") {
      if (!ress1.otp) {
        const res = await fetch(`${Config.apiurl}forgot-password/verify_otp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            otp: otp,
          }),
        });
        const data1 = await res.json();
        if (data1.token) {
          localStorage.setItem("user_name", data1.user.name);

          // const compressedString = LZString.compress(data1.user.user_type);
          // localStorage.setItem("user_type",compressedString)

          const encryptedUserType = CryptoJS.AES.encrypt(
            data1.user.user_type,
            secretKey
          ).toString();
          localStorage.setItem("user_type", encryptedUserType);

          localStorage.removeItem("pass");
          let token = data1.token;
          localStorage.setItem("token", token);
          localStorage.setItem("refreshtoken", data1.refresh_token);

          // setTokenSegmentsInCookies(token, 1500);

          localStorage.removeItem("selected-hotel-id");
          localStorage.removeItem("selected-hotel");

          setAlert(true);

          setAlertmessage(data1.message);

          setAlerttype("success");
          setButton_disable(false);
          setTimeout(() => navigate("/resetpassword"), 4000);
        } else {
          setAlertmessage(data1.message);
          setAlerttype("error");
          setButton_disable(false);
        }
      }
    } else {
      if (!ress1.otp) {
        const res = await fetch(`${Config.apiurl}admin/verify_otp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            otp: otp,
          }),
        });
        const data1 = await res.json();
        console.log(data1);
        if (data1.token) {
          localStorage.setItem("user_name", data1.user.name);

          // const compressedString = LZString.compress(data1.user.user_type);
          // localStorage.setItem("user_type",compressedString)
          const encryptedUserType = CryptoJS.AES.encrypt(
            data1.user.user_type,
            secretKey
          ).toString();
          localStorage.setItem("user_type", encryptedUserType);

          localStorage.removeItem("pass");

          let token = data1.token;
          localStorage.setItem("token", token);
          localStorage.setItem("refreshtoken", data1.refresh_token);

          // setTokenSegmentsInCookies(token, 1500);

          localStorage.removeItem("selected-hotel-id");
          localStorage.removeItem("selected-hotel");
          setButton_disable(false);
          setAlert(true);
          if (data1.message === "User login successfully") {
            setAlertmessage(
              !languagedata.lang_array || languagedata.lang_array.length === 0
                ? languagedata.en.login_success
                : languagedata.lang_array.login_success
                  ? languagedata.lang_array.login_success
                  : languagedata.en.login_success
            );
          }
          setAlerttype("success");
          setButton_disable(false);
          setTimeout(() =>
          window.location.href="/dashboard", 4000);
          // setTimeout(() => navigate("/dashboard"), 4000);
        } else {
          setAlert(true);
          if (data1.message === "error" || data1.message === "Invalid") {
            setAlertmessage(
              !languagedata.lang_array || languagedata.lang_array.length === 0
                ? languagedata.en.error
                : languagedata.lang_array.error
                  ? languagedata.lang_array.error
                  : languagedata.en.error
            );
            setButton_disable(false);
          } else if (data1.message === "The Time Expired") {
            setAlertmessage(
              !languagedata.lang_array || languagedata.lang_array.length === 0
                ? languagedata.en.time_expired
                : languagedata.lang_array.time_expired
                  ? languagedata.lang_array.time_expired
                  : languagedata.en.time_expired
            );
            setButton_disable(false);
          } else if (
            data1.message ===
            "Login Pending, You have 2 active logins detected!. Logout 1 Device to Continue."
          ) {
            setAlertmessage(
              !languagedata.lang_array || languagedata.lang_array.length === 0
                ? languagedata.en.login_pending
                : languagedata.lang_array.login_pending
                  ? languagedata.lang_array.login_pending
                  : languagedata.en.login_pending
            );
            setLogindetails(data1.data);
            setButton_disable(false);
          } else {
            setAlertmessage(
              !languagedata.lang_array || languagedata.lang_array.length === 0
                ? languagedata.en.invalid
                : languagedata.lang_array.invalid
                  ? languagedata.lang_array.invalid
                  : languagedata.en.invalid
            );
          }
          setAlerttype("error");
          setButton_disable(false);
        }
      }
    }
  };

  function splitStringIntoSegments(str, segmentSize) {
    const segments = [];
    for (let i = 0; i < str.length; i += segmentSize) {
      segments.push(str.slice(i, i + segmentSize));
    }
    return segments;
  }

  // Function to set token segments in cookies
  function setTokenSegmentsInCookies(token, segmentSize) {
    const segments = splitStringIntoSegments(token, segmentSize);

    segments.forEach((segment, index) => {
      Cookies.set(`tokenSegment${index}`, segment);
    });
    Cookies.set("size", segments.length);
  }

  useEffect(() => {
    getinfo();
  }, [languageid]);

  const Resend = async (e) => {
    const res = await fetch(`${Config.apiurl}resend-otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        password: pass,
      }),
    });
    const data = await res.json();
    if (data.status !== 200 || !data) {
      console.log("error");
    } else {
      setAlert(true);
      setAlertmessage(data.message);
      setAlerttype("success");
    }
  };

  const handleLogout = (session_id) => {
    const updatedLogindetails = logindetails.filter(
      (data) => data.user_sessions_id !== session_id
    );
    setLogindetails(updatedLogindetails);
    DeleteSession(session_id);
  };

  const DeleteSession = async (session_id) => {
    const res = await fetch(`${Config.apiurl}verify_otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        otp: otp,
        delete_session: [session_id],
      }),
    });
    const data1 = await res.json();
    if (data1.token) {
      localStorage.setItem("user_name", data1.user.name);
      const encryptedUserType = CryptoJS.AES.encrypt(
        data1.user.user_type,
        secretKey
      ).toString();
      localStorage.setItem("user_type", encryptedUserType);

      let token = data1.token;
      localStorage.setItem("token", token);
      localStorage.setItem("refreshtoken", data1.refresh_token);
      localStorage.removeItem("selected-hotel-id");
      localStorage.removeItem("selected-hotel");
      setAlertmessage(data1.message);
      setAlert(true);
      // setTimeout(() => navigate("/dashboard"), 4000);
      setTimeout(() => 
      window.location.href="/dashboard", 4000);
      setAlerttype("success");
    } else {
      setAlert(true);
      setAlertmessage(data1.message);
      setAlerttype("error");
    }
  };

  const getBrowserType = (userAgent) => {
    if (userAgent.includes("Chrome")) {
      return "Chrome";
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      return "Safari";
    } else if (userAgent.includes("Firefox")) {
      return "Firefox";
    } else if (userAgent.includes("Edge")) {
      return "Edge";
    } else {
      return "Unknown Browser";
    }
  };

  const getDeviceType = (userAgent) => {
    if (userAgent.includes("Macintosh")) {
      return "Mac";
    } else if (userAgent.includes("Windows")) {
      return "Windows";
    } else if (userAgent.includes("iPhone") || userAgent.includes("Android")) {
      return "Mobile";
    } else if (userAgent.includes("iPad")) {
      return "iPad";
    } else {
      return "Unknown Device";
    }
  };

  return (
    <>
      {loading === false ? (
        <Loading />
      ) : (
        <div className="wrapper">
          <div className="row">
            <div className="flex-placed ">
              {/* image */}
              <div className="col-md-6 img-sec-login">
                <div className="login-main2">
                  <div id="LoginHDisplay.Rowset1" className="xy-center">
                    <p id="LoginHDisplay.Rb10">
                      <span id="LoginHDisplay.Rb10.C2" className="alignvideo">
                        <video
                          className="video-slide active"
                          src="images/rooms/h.mp4"
                          autoPlay
                          muted
                          loop
                          // width="1280"
                          // height="720"
                        ></video>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 form-section-2">
                <div className="login-main">
                  <div className="login-box-style">
                    <div className="login-box">
                      <div className="login-inner-box">
                        {alert ? (
                          <Alerts type={alerttype} message={alertmessage} />
                        ) : (
                          ""
                        )}
                        {logindetails.length > 0 ? (
                          <div style={{ padding: "0 10%" }}>
                            <div className="device_logout_div">
                              {logindetails.map((data, index) => {
                                return (
                                  <div
                                    className="d-flex"
                                    key={index}
                                    style={{ padding: "1% 15%" }}
                                  >
                                    <span>
                                      {getDeviceType(data.user_agent)} -{" "}
                                      {getBrowserType(data.user_agent)}
                                    </span>
                                    <span
                                      className="device_logout_button"
                                      onClick={() =>
                                        handleLogout(data.user_sessions_id)
                                      }
                                    >
                                      Logout
                                    </span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {/* {logindetails.length>0?
                      <div className="device_logout_div">
                          {logindetails.map((data, index) => {
                            return (
                              <div className="d-flex" key={index} style={{padding:"10px 5px"}}>
                              <span style={{fontSize:"12px"}}>{data.user_agent}</span>
                              <span className="device_logout_button" onClick={()=>handleLogout(data.user_sessions_id)}>Logout</span>
                              </div>
                            );
                          })}
                      </div>
                      :""} */}

                        <div className="login-logo">
                          {/* <img src="assets/images/small-logo.png" alt="" /> */}
                          <img src="/images/IconDR.png" alt="" />
                        </div>
                        {exampleParameter === "forgot" ? (
                          <div className="login-content">
                            <h2>Forgot Password Verification</h2>
                          </div>
                        ) : (
                          <div className="login-content">
                            <h2>
                              {!languagedata.lang_array ||
                              languagedata.lang_array.length === 0
                                ? languagedata.en.verify_title
                                : languagedata.lang_array.verify_title
                                  ? languagedata.lang_array.verify_title
                                  : languagedata.en.verify_title}
                            </h2>
                          </div>
                        )}

                        <div className="verify-content">
                          <p>
                            {!languagedata.lang_array ||
                            languagedata.lang_array.length === 0
                              ? languagedata.en.send_code_content
                              : languagedata.lang_array.send_code_content
                                ? languagedata.lang_array.send_code_content
                                : languagedata.en.send_code_content}
                            <br />
                            {v1}
                          </p>
                          <p>
                            {!languagedata.lang_array ||
                            languagedata.lang_array.length === 0
                              ? languagedata.en.pls_enter_code_content
                              : languagedata.lang_array.pls_enter_code_content
                                ? languagedata.lang_array.pls_enter_code_content
                                : languagedata.en.pls_enter_code_content}
                          </p>
                        </div>
                        <div className="login-form-field">
                          <div className="login-form">
                            <input
                              type="text"
                              className="verify-text-field"
                              placeholder={
                                !languagedata.lang_array ||
                                languagedata.lang_array.length === 0
                                  ? languagedata.en.code_placeholder
                                  : languagedata.lang_array.code_placeholder
                                    ? languagedata.lang_array.code_placeholder
                                    : languagedata.en.code_placeholder
                              }
                              value={otp}
                              onChange={(e) => setOtp(e.target.value)}
                            />
                            <label style={{ color: "red" }}>
                              {formerror.otp}
                            </label>
                          </div>
                          <div className="login-verify-section">
                            <p onClick={Resend}>
                              {!languagedata.lang_array ||
                              languagedata.lang_array.length === 0
                                ? languagedata.en.resend
                                : languagedata.lang_array.resend
                                  ? languagedata.lang_array.resend
                                  : languagedata.en.resend}
                            </p>
                          </div>
                          {button_disable === false ?
                          <Link
                            style={{ color: "white" }}
                            to="#"
                            onClick={handleButtonClick}
                          >
                            <div className="login-submit dr_login_btn">
                              {!languagedata.lang_array ||
                              languagedata.lang_array.length === 0
                                ? languagedata.en.go_button
                                : languagedata.lang_array.go_button
                                  ? languagedata.lang_array.go_button
                                  : languagedata.en.go_button}{" "}
                            </div>
                          </Link>
                          :
                          <div className="d-flex justify-content-center my-3 custom-spinner">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        </div>
                        }
                          <div className="login-bottom">
                            <Link to="/" className="login-bottom-back">
                              {!languagedata.lang_array ||
                              languagedata.lang_array.length === 0
                                ? languagedata.en.return_login
                                : languagedata.lang_array.return_login
                                  ? languagedata.lang_array.return_login
                                  : languagedata.en.return_login}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Verify;
